<template>
  <div :class="{ hidden: !ordersDuplicate.length }" class="slideInUp">
    <div class="row justify-content-between">
      <div class="col text-left">
        <span class="spanL">Дизайнер: {{ designer.name }}</span>
        <div class="w-100"></div>
        <span class="spanL">План: {{ designer.planAsText }}</span>
      </div>
      <div class="col text-right">
        <span class="spanL">Заказов: {{ ordersCount }}</span>
        <div class="w-100"></div>
        <span class="spanL">Баллов: {{ dsgnrPoint }}</span>
      </div>
    </div>
    <table class="text-roboto mb-2 w-100 tableL table-min">
      <thead class="text-center theadL">
        <tr>
          <th>№</th>
          <th>Номер заказа</th>
          <th>Дата отправки</th>
          <th>Дата готовности макета</th>
          <th>Портрет нарисован</th>
          <th>Заказ</th>
          <th>Баллы</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="trL"
          v-for="(order, index) in normalView"
          :key="order.id"
          :class="{ hidden: index >= 5 && !ordersOpen }"
        >
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-center">{{ order.id }}</td>
          <td class="text-center">{{ order.dateShip }}</td>
          <td class="text-center">{{ order.dateMaket }}</td>
          <td class="text-center">{{ order.dateNarisovana }}</td>
          <td v-html="order.basket"></td>
          <td class="text-right">{{ order.point }}</td>
        </tr>
      </tbody>
    </table>
    <div class="row mb-4">
      <div :class="{ hidden: !noTime }" class="text-left text-danger col-6">
        <h5 class="h5L">У дизайнера нет места</h5>
      </div>
      <div :class="{ hidden: noTime }" class="col-6"></div>
      <div :class="{ hidden: !moreFive }" class="text-right col-6">
        <div @click="openOrders" :class="{ hidden: ordersOpen }">
          <mdb-icon icon="glasses " />
        </div>
        <div @click="openOrders" :class="{ hidden: !ordersOpen }">
          <mdb-icon icon="eye-slash " />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
/* .table-min {
  min-height: 30vh;
} */
/* .trL:hover {
  background-color: #000;
} */
/* tr:hover {
  color: rgb(225, 222, 222);
  background-color: rgb(84, 80, 80);
} */
.theadL {
  font-size: 101%;
  color: rgb(42, 42, 43);
}
.h5L {
  font-size: 95%;
  font-weight: 600;
  color: rgb(180, 30, 30);
}
.card-eye {
  font-size: 60%;
}
.tableL {
  border-radius: 0.3rem;
  background-color: rgb(255, 255, 255);
  /* min-height: 15rem; */
}

table tbody .trL:nth-child(odd) {
  background: rgba(173, 192, 183, 0.159);
}
table tbody .trL:nth-child(even) {
  background: #ffffff38;
}

.spanL {
  font-weight: 500;
  color: rgba(44, 44, 44, 0.898);
}
</style>

<script>
import Vue from "vue";
import { bus } from "@/main";
import { mdbIcon } from "mdbvue";

export default {
  components: {
    mdbIcon,
  },
  props: ["orders", "designer", "baketPoint"],
  data() {
    return {
      ordersDuplicate: this.orders,
      ordersCount: 0,
      dsgnrPoint: 0,
      noTime: false,
      moreFive: false,
      rerenderCount: 0,
      ordersOpen: false,
    };
  },
  computed: {
    selectedOrders() {
      if (this.rerenderCount === 0) {
        this.ordersDuplicate = this.orders.filter((item) => {
          for (let i = 0, l = item.customFields.length; i < l; i += 1) {
            const field = item.customFields[i];
            if (field.fieldId === 86 && field.value === today) return true;
          }
        });
      }
      let usedWorker = this.designer.id;
      let usedStatus = [];
      return this.ordersDuplicate.filter((item) => {
        for (let i = 0, l = item.customFields.length; i < l; i += 1) {
          const field = item.customFields[i];
          if (
            field.fieldId === 85 &&
            (field.value === usedWorker || usedWorker === "0") &&
            (!usedStatus.length ||
              usedStatus.indexOf(item.orderStatus.id) !== -1)
          ) {
            return true;
          }
        }
        return false;
      });
    },
    normalView() {
      const orders = this.selectedOrders;
      this.noTime = false;
      this.moreFive = false;
      if (orders.length === 0) this.ordersDuplicate = [];
      let sumPoint = 0;
      const ordersForREal = orders.map((item) => {
        const res = {
          id: item.internalNumber,
          dateShip: "",
          dateMaket: "",
          dateNarisovana: "",
          basket: [],
          point: 0,
          dateSoglasovaniya: "",
        };
        for (let i = 0, l = item.customFields.length; i < l; i += 1) {
          const field = item.customFields[i];
          switch (field.fieldId) {
            case 88: {
              res.dateShip = field.value.slice(0, -5);
              break;
            }
            case 86: {
              res.dateMaket = field.value.slice(0, -5);
              break;
            }
            case 1381: {
              res.dateNarisovana = field.value.slice(0, -5);
              break;
            }
            case 1382: {
              res.dateSoglasovaniya = field.value.slice(0, -5);
              break;
            }
          }
        }
        let basket = [];
        let point = 0;
        for (let i = 0, l = item.goodsPositions.length; i < l; i += 1) {
          const goodsItem = item.goodsPositions[i];
          let itemPoint = String(goodsItem.goods.marking);
          if (this.baketPoint.basket4.includes(itemPoint))
            point += 4 * goodsItem.quantity;
          if (this.baketPoint.basket2.includes(itemPoint))
            point += 2 * goodsItem.quantity;
          if (this.baketPoint.basket1.includes(itemPoint))
            point += 1 * goodsItem.quantity;
          basket.push(
            `${goodsItem.goods.marking}&nbsp;-&nbsp;${goodsItem.quantity}`
          );
        }
        res.basket = basket.join(", ");
        res.point = point;
        sumPoint += point;
        return res;
      });
      this.ordersCount = ordersForREal.length;
      this.dsgnrPoint = sumPoint;
      this.rerenderCount += 1;
      if (this.ordersCount > 5) this.moreFive = true;
      if (this.dsgnrPoint >= this.designer.plan) this.noTime = true;
      return ordersForREal;
    },
  },
  created() {
    bus.$on("yesterday", (data) => {
      this.ordersDuplicate = this.orders.filter((item) => {
        for (let i = 0, l = item.customFields.length; i < l; i += 1) {
          const field = item.customFields[i];
          if (field.fieldId === 86 && field.value === yesterday) return true;
        }
      });
    });
    bus.$on("today", (data) => {
      this.ordersDuplicate = this.orders.filter((item) => {
        for (let i = 0, l = item.customFields.length; i < l; i += 1) {
          const field = item.customFields[i];
          if (field.fieldId === 86 && field.value === today) return true;
        }
      });
    });
    bus.$on("tomorrow", (data) => {
      this.ordersDuplicate = this.orders.filter((item) => {
        for (let i = 0, l = item.customFields.length; i < l; i += 1) {
          const field = item.customFields[i];
          if (field.fieldId === 86 && field.value === tomorrow) return true;
        }
      });
    });
    bus.$on("afterTomorrow", (data) => {
      this.ordersDuplicate = this.orders.filter((item) => {
        for (let i = 0, l = item.customFields.length; i < l; i += 1) {
          const field = item.customFields[i];
          if (field.fieldId === 86 && field.value === afterTomorrow)
            return true;
        }
      });
    });
  },
  methods: {
    openOrders() {
      this.ordersOpen = !this.ordersOpen;
    },
  },
};
let today = new Date().toLocaleDateString();
let yesterday = new Date(new Date().getTime() - 86400000).toLocaleDateString();
let tomorrow = new Date(new Date().getTime() + 86400000).toLocaleDateString();
let afterTomorrow = new Date(
  new Date().getTime() + 172800000
).toLocaleDateString();
</script>