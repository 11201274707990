<template>
  <div>
    <mdb-navbar color="elegant" dark class="navnav w-100">
      <mdb-navbar-toggler>
        <mdb-navbar-nav left>
          <mdb-dropdown tag="li" class="nav-item animated fadeInDown">
            <mdb-dropdown-toggle
              icon="caret-up"
              tag="a"
              navLink
              slot="toggle"
              waves-fixed
            ></mdb-dropdown-toggle>
            <mdb-dropdown-menu>
              <mdb-dropdown-item @click="openDesignerLoad"
                >Нагрузка дизайнеров</mdb-dropdown-item
              >
              <mdb-dropdown-item @click="openReport" active
                >Отчет</mdb-dropdown-item
              >
            </mdb-dropdown-menu>
          </mdb-dropdown>

          <mdb-nav-item class="animated fadeInDown" @click="modal_date = true">
            <mdb-icon far icon="calendar-check" size="lg" />
          </mdb-nav-item>

          <mdb-nav-item>
            <select
              v-if="orders != null && orders.length"
              v-model="designer"
              class="mx-1"
              :disabled="!activeWorkers || !activeWorkers.length"
            >
              <option value="0">Все</option>
              <option
                v-for="item in activeWorkers"
                :key="item.id"
                :value="item"
              >
                {{ item.name }}
              </option>
            </select>
          </mdb-nav-item>
          <mdb-nav-item v-if="orders != null && orders.length">
            <span class="mx-2">Заказов: {{ ordersCount }}</span>
            <span class="mx-2">Баллов: {{ dsgnrPoint }}</span>
          </mdb-nav-item>
        </mdb-navbar-nav>

        <mdb-navbar-nav right>
          <mdb-nav-item @click="logout">Выход</mdb-nav-item>
        </mdb-navbar-nav>
      </mdb-navbar-toggler>
    </mdb-navbar>
    <mdb-modal
      :show="modal_date"
      @close="modal_date = false"
      class="modalmodal fadeInDown"
    >
      <mdb-modal-header>
        <mdb-modal-title>Задать дату выборки</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="my-2 text-center">
        <div class="row justify-content-center">
          <input class="mx-4" v-model="dateFrom" type="date" min="2020-01-01" />

          <input class="mx-4" v-model="dateTill" type="date" min="2020-01-01" />
        </div>
        <div class="row mt-4 justify-content-center">
          <div class="col">
            <h4>Статусы</h4>
            <div class="w-100"></div>
            <select
              v-model="statuses"
              @change="orders = null"
              multiple="yes"
              class="ml-3 selectR"
              size="10"
            >
              <option class="px-2 py-1" value="0">Все</option>
              <option
                class="px-2 py-1"
                v-for="item in statuses_all"
                :key="item.name"
                :value="item.name"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-2 justify-content-center">
          <button
            v-if="!loading"
            class="btn btn-elegant"
            :disabled="!dateFrom.length || !dateTill.length || loading"
            @click="getOrders"
          >
            <span class="mx-2"> Загрузить отчёт</span>
          </button>
          <div v-else class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <!-- <label>
      Дизайнеры:
      <select
        v-model="designer"
        class="mx-1"
        :disabled="!activeWorkers || !activeWorkers.length"
      >
        <option value="0">Все</option>
        <option v-for="item in activeWorkers" :key="item.id" :value="item">
          {{ item.name }}
        </option>
      </select>
    </label> -->

    <!-- <br />

    <button
      @click="getOrders"
      :disabled="!dateFrom.length || !dateTill.length || loading"
    >
      Отчет
    </button>
    <button
      @click="sortSoglasovanie"
      :disabled="!activeWorkers || !activeWorkers.length"
    >
      по дате согласования
    </button> -->

    <div v-if="orders != null && orders.length">
      <table class="container-fluid tableL">
        <thead>
          <tr class="text-center">
            <th>№</th>
            <th>Номер заказа</th>
            <th>Дата отправки</th>
            <th>Дата готовности макета</th>
            <th>Портрет нарисован</th>
            <th>Заказ</th>
            <th>Баллы</th>
            <th>Дата согласования</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="trL text-center"
            v-for="(order, index) in normalView"
            :key="order.id"
          >
            <td>{{ index + 1 }}</td>
            <td class="text-center">{{ order.id }}</td>
            <td class="text-center">{{ order.dateShip }}</td>
            <td class="text-center">{{ order.dateMaket }}</td>
            <td class="text-center">{{ order.dateNarisovana }}</td>
            <td class="text-right" v-html="order.basket"></td>
            <td class="text-right">{{ order.point }}</td>
            <td class="text-center">{{ order.dateSoglasovaniya }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style>
select {
  max-width: 80%;
}
.iconReport:hover {
  transform: scale(1.2);
  color: rgb(25, 148, 25);
  cursor: pointer;
}
.iconReport:active {
  color: red;
  transform: scale(1.1);
}

.selectR {
  border: red 2 px solid;
  border-radius: 0.2rem;
  outline: none;
  opacity: 0.9;
}
</style>

<script>
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { bus } from "@/main";
import {
  mdbNavbar,
  mdbNavbarBrand,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbNavItem,
  mdbIcon,
  mdbDropdown,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbDropdownItem,
  mdbFormInline,
  mdbInput,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
  mdbBtn,
  mdbTooltip,
} from "mdbvue";

export default {
  components: {
    mdbBtn,
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavItem,
    mdbIcon,
    mdbDropdown,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbDropdownItem,
    mdbFormInline,
    mdbInput,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbTooltip,
  },
  props: ["baketPoint"],
  data() {
    return {
      orders: null,
      statuses_all: [
        { name: "Картина Отправлена" },
        { name: "Передан дизайнеру" },
        { name: "Согласование" },
        { name: "Черновик" },
        { name: "Правки" },
        { name: "Ждём 2 предоплату" },
        {
          name: "Печать СПб (ты предложил допы и вставил ССЫЛКУ НА МАКЕТЫ????)",
        },
        { name: "Печать МОСКВА" },
        { name: "В производстве" },
        { name: "Картина Готова СПБ" },
        { name: "Картина Готова МСК" },
        { name: "Документы оформлены" },
        { name: "Добавлен в план" },
        { name: "Картина готова без допов" },
        { name: "Картина нарисована" },
        { name: "Прислал на печать" },
        { name: "Получен" },
        { name: "Не вручен" },
        { name: "Возврат" },
        { name: "Отменен" },
        { name: "Ждем полную оплату" },
        { name: "Переделка" },
        { name: "Не оплачен" },
        { name: "Не передан заказ" },
        { name: "Клиент не отвечает" },
        { name: "Битрикс" },
      ],
      statuses: ["0"],
      designer: "0",
      dateFrom: "",
      dateTill: "",
      ordersCount: 0,
      dsgnrPoint: 0,
      loading: false,

      modal_date: true,
    };
  },
  computed: {
    selectedOrders() {
      let usedWorker = this.designer.id || "0";
      return this.orders.filter((item) => {
        for (let i = 0, l = item.customFields.length; i < l; i += 1) {
          const field = item.customFields[i];
          if (
            field.fieldId === 85 &&
            (field.value === usedWorker || usedWorker === "0")
          ) {
            return true;
          }
        }
        return false;
      });
    },
    normalView() {
      let sumPoint = 0;
      const orders = this.selectedOrders;
      const ordersForREal = orders.map((item) => {
        const res = {
          id: item.internalNumber,
          dateShip: "",
          dateMaket: "",
          dateNarisovana: "",
          basket: [],
          point: 0,
          dateSoglasovaniya: "",
        };
        for (let i = 0, l = item.customFields.length; i < l; i += 1) {
          const field = item.customFields[i];
          switch (field.fieldId) {
            case 88: {
              res.dateShip = field.valueAsText.slice(0, -4);
              break;
            }
            case 86: {
              res.dateMaket = field.valueAsText.slice(0, -4);
              break;
            }
            case 1381: {
              res.dateNarisovana = field.valueAsText.slice(0, -4);
              break;
            }
            case 1382: {
              res.dateSoglasovaniya = field.valueAsText.slice(0, -4);
              break;
            }
          }
        }
        let basket = [];
        let point = 0;
        for (let i = 0, l = item.goodsPositions.length; i < l; i += 1) {
          const goodsItem = item.goodsPositions[i];
          let itemPoint = String(goodsItem.goods.marking);
          if (this.baketPoint.basket4.includes(itemPoint))
            point += 4 * goodsItem.quantity;
          if (this.baketPoint.basket2.includes(itemPoint))
            point += 2 * goodsItem.quantity;
          if (this.baketPoint.basket1.includes(itemPoint))
            point += 1 * goodsItem.quantity;
          basket.push(
            `${goodsItem.goods.marking}&nbsp;-&nbsp;${goodsItem.quantity}`
          );
        }
        res.basket = basket.join(", ");
        res.point = point;
        sumPoint += point;
        return res;
      });
      this.ordersCount = ordersForREal.length;
      this.dsgnrPoint = sumPoint;
      return ordersForREal;
    },
    activeWorkers() {
      if (!this.orders) {
        return [];
      }
      const workers = [];
      const has = [];
      for (let i = 0, l = this.orders.length; i < l; i += 1) {
        const fields = this.orders[i].customFields;
        for (let i = 0, l = fields.length; i < l; i += 1) {
          const field = fields[i];
          if (field.fieldId === 85 && has.indexOf(field.value) === -1) {
            workers.push({
              id: field.value,
              name: field.valueAsText,
            });
            has.push(field.value);
          }
        }
      }
      return workers;
    },
  },
  methods: {
    async getOrders() {
      this.loading = true;

      let dateFrom_date = new Date(`${this.dateFrom}`);
      dateFrom_date.setDate(dateFrom_date.getDate() - 7);
      const dateFrom = dateFrom_date.toISOString().substr(0, 10);
      const dateTill = this.dateTill;

      let statuses = [];
      for (let i in this.statuses) {
        statuses.push({ name: `${this.statuses[i]}` });
      }
      let orders_all = await request(
        `http://45.84.227.225/api/orders_all`,
        "POST",
        {
          dateFrom,
          dateTill,
          statuses,
        }
      );
      // this.orders = orders_all;
      this.loading = false;
      this.modal_date = false;
      if (orders_all === "Ошибка...") {
        alert(
          "Ошибка......Рекомендуемое время повторного запроса: 1      минута."
        );
      }
      let dateForSort = [];
      let df = new Date(String(this.dateFrom));
      let dt = new Date(String(this.dateTill));
      while (df < dt) {
        dateForSort.push(`${df.toLocaleDateString()}`);
        df.setDate(df.getDate() + 1);
      }
      dateForSort.push(`${dt.toLocaleDateString()}`);
      this.orders = orders_all.filter((item) => {
        for (let i = 0, l = item.customFields.length; i < l; i += 1) {
          const field = item.customFields[i];
          if (field.fieldId === 1381 && dateForSort.includes(field.value))
            return true;
        }
      });
    },

    openDesignerLoad() {
      bus.$emit("openDesignerLoadB");
      this.isAopenReport = true;
    },
    openReport() {
      bus.$emit("openReportB");
      this.isAopenReport = false;
      this.isActiveToday = true;
      this.isActiveAfterTomorrow = false;
      this.isActiveTomorrow = false;
      this.isActiveYesterday = false;
    },
    async logout() {
      await this.$store.dispatch(AUTH_LOGOUT);
      this.$router.push("/");
    },
  },
  async mounted() {
    this.worker = await localStorage.getItem("user-login");
  },
};

async function request(url, method = "GET", data = null) {
  try {
    const headers = {};
    let body;

    if (data) {
      headers["Content-Type"] = "application/json";
      body = JSON.stringify(data);
    }

    const response = await fetch(url, {
      method,
      headers,
      body,
    });

    return await response.json();
  } catch (e) {
    console.warn("Error:", e.message);
  }
}
</script>